import { Draft, PayloadAction, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import {
  Action,
  IfThenRuleConditionWithId,
  IfThenWithConditionIds,
  WorkingRoutingConfigurationNode,
} from '../../types';
import { parseConditionJoiner } from '../../utils/parseConditionJoiner';
import { actionHasAnyBlankFields, conditionsHaveAnyBlankFields2 } from '../../utils/validation';

export interface NodeMetadata {
  name?: string;
  description?: string;
  defaultActionId?: string;
  defaultNextNodeId?: string;
  isStartingNode?: boolean;
}

export interface ConditionGroupMetadata {
  id: string;
  nextNodeId?: string;
  joiner: 'all' | 'any';
  name?: string;
  description?: string;
  actionId?: string;
}

interface SelectedNodeSliceState {
  orderOfConditionGroups: string[];
  conditionGroupsToConditionIdsMap: { [key: string]: string[] };
  conditionGroupsToMetadataMap: {
    [key: string]: ConditionGroupMetadata;
  };
  conditionGroupsToActionIdsMap: { [key: string]: string };
  conditionsById: { [key: string]: IfThenRuleConditionWithId };
  actionsById: { [key: string]: Action };
  metadata: NodeMetadata;
}

// i.e. this includes all of the fields as well as a 'snapshot'
// of the node when it was selected, which includes all of those same fields
interface SelectedNodeState extends SelectedNodeSliceState {
  snapshot: SelectedNodeSliceState;
  canSave: boolean;
}

const initialState: SelectedNodeState = {
  orderOfConditionGroups: [],
  conditionGroupsToConditionIdsMap: {},
  conditionGroupsToMetadataMap: {},
  conditionGroupsToActionIdsMap: {},
  conditionsById: {},
  actionsById: {},
  metadata: {},
  canSave: false,
  snapshot: {
    orderOfConditionGroups: [],
    conditionGroupsToConditionIdsMap: {},
    conditionGroupsToMetadataMap: {},
    conditionGroupsToActionIdsMap: {},
    conditionsById: {},
    actionsById: {},
    metadata: {},
  },
};

const SelectedNodeSlice = createSlice({
  name: 'selectedNode',
  initialState,
  reducers: {
    setStateFromExistingNode(
      state,
      action: PayloadAction<{ node: WorkingRoutingConfigurationNode; isStartingNode: boolean }>,
    ) {
      const { node } = action.payload;

      const metadata = {
        name: node.name,
        description: node.description,
        defaultActionId: node.defaultActionId,
        defaultNextNodeId: node.defaultNextNodeId,
        isStartingNode: action.payload.isStartingNode,
      };
      state.metadata = metadata;
      state.snapshot.metadata = metadata;

      const orderOfConditionGroups = node.ifThens?.map((conditionGroup) => conditionGroup.id) || [];
      const defaultActionById = node.defaultAction
        ? {
            [node.defaultAction.id]: node.defaultAction,
          }
        : {};

      state.orderOfConditionGroups = orderOfConditionGroups;
      state.snapshot.orderOfConditionGroups = orderOfConditionGroups;

      let conditionsById = {};
      const conditionGroupsToMetadataMap = {};
      const conditionGroupsToConditionIdsMap = {};
      const conditionGroupsToActionIdsMap = {};
      const actionsById = { ...defaultActionById };

      for (const ifThen of node.ifThens || []) {
        const joiner = parseConditionJoiner(ifThen);
        conditionGroupsToMetadataMap[ifThen.id] = {
          id: ifThen.id,
          nextNodeId: ifThen.nextNodeId,
          joiner,
          name: ifThen.name,
          description: ifThen.description,
          actionId: ifThen.actionId,
        };
        const conditions: IfThenRuleConditionWithId[] = ifThen.rule.conditions[joiner] || [];
        conditionGroupsToConditionIdsMap[ifThen.id] = conditions.map((condition) => condition.id);
        if (ifThen.actionId) {
          conditionGroupsToActionIdsMap[ifThen.id] = ifThen.actionId;
        }
        conditionsById = { ...conditionsById, ..._.mapKeys(conditions, 'id') };
      }

      state.conditionGroupsToMetadataMap = conditionGroupsToMetadataMap;
      state.snapshot.conditionGroupsToMetadataMap = conditionGroupsToMetadataMap;
      state.conditionGroupsToConditionIdsMap = conditionGroupsToConditionIdsMap;
      state.snapshot.conditionGroupsToConditionIdsMap = conditionGroupsToConditionIdsMap;
      state.conditionGroupsToActionIdsMap = conditionGroupsToActionIdsMap;
      state.snapshot.conditionGroupsToActionIdsMap = conditionGroupsToActionIdsMap;
      state.conditionsById = conditionsById;
      state.snapshot.conditionsById = conditionsById;
      state.actionsById = actionsById;
      state.snapshot.actionsById = actionsById;

      state.canSave = false;
    },
    updateNodeMetadata: (state, action: PayloadAction<NodeMetadata>) => {
      state.metadata = { ...state.metadata, ...action.payload };
    },
    updateOrderOfConditionGroupIds: (state, action: PayloadAction<string[]>) => {
      state.orderOfConditionGroups = action.payload;
    },
    addBlankConditionGroup: (state, action: PayloadAction<IfThenWithConditionIds>) => {
      const conditionGroup = action.payload;
      const defaultCondition = conditionGroup!.rule!.conditions!.all![0];
      state.conditionsById[defaultCondition.id] = defaultCondition;
      state.conditionGroupsToMetadataMap[conditionGroup.id] = {
        id: conditionGroup.id,
        joiner: 'all',
        name: conditionGroup.name,
        description: conditionGroup.description,
      };
      state.conditionGroupsToConditionIdsMap[conditionGroup.id] = [defaultCondition.id];
      state.orderOfConditionGroups.push(conditionGroup.id);
    },
    addConditionToConditionGroupId: (
      state,
      action: PayloadAction<{ conditionGroupId: string; conditionId: string }>,
    ) => {
      state.conditionGroupsToConditionIdsMap[action.payload.conditionGroupId].push(
        action.payload.conditionId,
      );
      state.conditionsById[action.payload.conditionId] = {
        id: action.payload.conditionId,
        value: '',
      };
    },
    deleteConditionGroupById: (state, action: PayloadAction<string>) => {
      const conditionIdsToDelete = state.conditionGroupsToConditionIdsMap[action.payload];
      conditionIdsToDelete.forEach((conditionId) => {
        delete state.conditionsById[conditionId];
      });
      const actionIdToDelete = state.conditionGroupsToActionIdsMap[action.payload];
      if (actionIdToDelete) {
        delete state.actionsById[actionIdToDelete];
      }
      state.conditionGroupsToConditionIdsMap = _.omit(
        state.conditionGroupsToConditionIdsMap,
        action.payload,
      );
      state.conditionGroupsToMetadataMap = _.omit(
        state.conditionGroupsToMetadataMap,
        action.payload,
      );
      state.conditionGroupsToActionIdsMap = _.omit(
        state.conditionGroupsToActionIdsMap,
        action.payload,
      );
      state.orderOfConditionGroups = state.orderOfConditionGroups.filter(
        (conditionGroupId) => conditionGroupId !== action.payload,
      );
    },
    updateConditionGroupMetadata: (
      state,
      action: PayloadAction<{
        conditionGroupId: string;
        metadata: ConditionGroupMetadata;
      }>,
    ) => {
      state.conditionGroupsToMetadataMap[action.payload.conditionGroupId] = action.payload.metadata;
    },
    updateActionForConditionGroupId: (
      state,
      action: PayloadAction<{ conditionGroupId: string; action: Action }>,
    ) => {
      state.conditionGroupsToActionIdsMap[action.payload.conditionGroupId] =
        action.payload.action.id;
      state.actionsById[action.payload.action.id] = action.payload.action;
    },
    deleteActionForConditionGroupId: (state, action: PayloadAction<string>) => {
      const actionIdToDelete = state.conditionGroupsToActionIdsMap[action.payload];
      state.conditionGroupsToActionIdsMap = _.omit(
        state.conditionGroupsToActionIdsMap,
        action.payload,
      );
      state.actionsById = _.omit(state.actionsById, actionIdToDelete);
    },
    updateDefaultAction: (state, action: PayloadAction<Action>) => {
      state.metadata.defaultActionId = action.payload.id;
      state.actionsById[action.payload.id] = action.payload;
    },
    deleteDefaultAction: (state) => {
      if (state.metadata.defaultActionId) {
        state.actionsById = _.omit(state.actionsById, state.metadata.defaultActionId);
        state.metadata.defaultActionId = undefined;
      }
    },
    updateDefaultNextNode: (state, action: PayloadAction<string>) => {
      state.metadata.defaultNextNodeId = action.payload;
    },
    updateConditions: (state, action: PayloadAction<IfThenRuleConditionWithId[]>) => {
      _.forEach(action.payload, (condition) => {
        state.conditionsById[condition.id] = condition;
      });
    },
    deleteCondition: (state, action: PayloadAction<string>) => {
      state.conditionsById = _.omit(state.conditionsById, action.payload);
      state.conditionGroupsToConditionIdsMap = _.mapValues(
        state.conditionGroupsToConditionIdsMap,
        (conditionIds) => conditionIds.filter((conditionId) => conditionId !== action.payload),
      );
    },
    clearSelectedNode: () => initialState,
    postReducerValidateState: (state) => {
      validateSavableState(state);
    },
  },
});

const validateSavableState = (state: Draft<SelectedNodeState>) => {
  const conditionsHaveBlanks = conditionsHaveAnyBlankFields2(
    Object.values(state.conditionsById),
    Object.values(state.actionsById),
  );
  if (conditionsHaveBlanks) {
    state.canSave = false;
    return;
  }

  const defaultActionHasBlanks =
    state.metadata.defaultActionId &&
    actionHasAnyBlankFields(state.actionsById[state.metadata.defaultActionId]);
  if (defaultActionHasBlanks) {
    state.canSave = false;
    return;
  }

  const metadataChanged = !_.isEqual({ ...state.metadata }, { ...state.snapshot.metadata });
  if (metadataChanged) {
    state.canSave = true;
    return;
  }

  const conditionGroupIdsChanged = !_.isEqual(
    [...state.orderOfConditionGroups],
    [...state.snapshot.orderOfConditionGroups],
  );
  if (conditionGroupIdsChanged) {
    state.canSave = true;
    return;
  }

  const conditionGroupMetadataChanged = _.some(
    Object.keys(state.conditionGroupsToMetadataMap),
    (ifThenId) => {
      return !_.isEqual(
        state.conditionGroupsToMetadataMap[ifThenId],
        state.snapshot.conditionGroupsToMetadataMap[ifThenId],
      );
    },
  );

  if (conditionGroupMetadataChanged) {
    state.canSave = true;
    return;
  }

  // Compare conditionIDs between the snapshot and current working state;
  // since these are UUIDs, it's not possible for a given condition ID
  // to move to a different IfThen

  const listOfConditionIds = Object.keys(state.snapshot.conditionsById).length
    ? Object.keys(state.snapshot.conditionsById)
    : Object.keys(state.conditionsById);

  const conditionIdsDiffer = _.some(listOfConditionIds, (ifThenId) => {
    const snapshotConditionIds = [...(state.conditionGroupsToConditionIdsMap[ifThenId] || [])];
    const workingConditionIds = [
      ...(state.snapshot.conditionGroupsToConditionIdsMap[ifThenId] || []),
    ];
    snapshotConditionIds.sort();
    workingConditionIds.sort();
    return !_.isEqual(snapshotConditionIds, workingConditionIds);
  });

  if (conditionIdsDiffer) {
    state.canSave = true;
    return;
  }

  // Compare the conditions themselves between the snapshot and current working state
  const conditionsDiffer = _.some(Object.keys(state.conditionsById), (conditionId) => {
    const workingCondition = state.conditionsById[conditionId];
    const snapshotCondition = state.snapshot.conditionsById[conditionId];
    return !_.isEqual(workingCondition, snapshotCondition);
  });

  if (conditionsDiffer) {
    state.canSave = true;
    return;
  }

  const actionsDiffer = _.some(Object.keys(state.actionsById), (actionId) => {
    const workingAction = state.actionsById[actionId];
    const snapshotAction = state.snapshot.actionsById[actionId];
    return !_.isEqual(workingAction, snapshotAction);
  });

  if (actionsDiffer) {
    state.canSave = true;
    return;
  }

  state.canSave = false;
};

// Run the validateSavableState action after every action
const multiReducer = (state, action) => {
  const intermediateState = SelectedNodeSlice.reducer(state, action);

  if (action.type !== SelectedNodeSlice.actions.postReducerValidateState.type) {
    return SelectedNodeSlice.reducer(
      intermediateState,
      SelectedNodeSlice.actions.postReducerValidateState(),
    );
  }

  // If the action was validateSavableState, just return the state
  return intermediateState;
};

export const {
  addBlankConditionGroup,
  deleteConditionGroupById,
  updateConditionGroupMetadata,
  updateConditions,
  deleteCondition,
  clearSelectedNode,
  addConditionToConditionGroupId,
  updateActionForConditionGroupId,
  deleteActionForConditionGroupId,
  updateNodeMetadata,
  updateOrderOfConditionGroupIds,
  updateDefaultAction,
  deleteDefaultAction,
  updateDefaultNextNode,
  setStateFromExistingNode,
} = SelectedNodeSlice.actions;

export default multiReducer;
