/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import _ from 'lodash';
import React from 'react';

import { getRequiredInputStatus } from '../../utils/validation';
import Tip from '../styledComponents/Tip';
import { RoutedToOrderedStyledTextField, RoutedToResultStyledTextField } from './RoutedToTextField';
import RoutedToTypeSelect from './RoutedToTypeSelect';

type SkuOrFulfillerId = 'skuCode' | 'fulfillerId';

type RoutedToValue = {
  orderedReferenceId: string;
  resultType: SkuOrFulfillerId;
  resultValue: string;
};

export default function RoutedToComponent({
  routedToValue,
  onUpdate,
}: {
  routedToValue: RoutedToValue;
  onUpdate: (value: RoutedToValue) => void;
}) {
  const { orderedReferenceId = '', resultType, resultValue = '' } = routedToValue;

  const updateParentWithChanges = (updates: Record<string, any>) => {
    onUpdate(_.merge({ ...routedToValue }, updates));
  };

  const handleResultTypeUpdate = ({ value }: { value: SkuOrFulfillerId }) => {
    updateParentWithChanges({
      resultType: value,
    });
  };

  const handleResultValueUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateParentWithChanges({
      resultValue: e.target.value,
    });
  };

  const handleOrderedValueUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateParentWithChanges({
      orderedReferenceId: e.target.value,
    });
  };

  return (
    <>
      <div css={tipStyles}>
        <Tip>
          The functionality of Also Routes has changed. It now checks to see if a particular result
          sku or fulfiller ID was routed to by any other item on the order with the specified
          ordered skuCode. The system automatically handles these item routing dependencies.
        </Tip>
      </div>
      <RoutedToOrderedStyledTextField
        value={`${orderedReferenceId}`}
        onChange={handleOrderedValueUpdate}
        label="Ordered SkuCode"
        status={getRequiredInputStatus(orderedReferenceId)}
      />
      <RoutedToTypeSelect
        value={resultType}
        onChange={handleResultTypeUpdate}
        label="Result Type"
        status={getRequiredInputStatus(resultType)}
      />
      <RoutedToResultStyledTextField
        value={`${resultValue}`}
        onChange={handleResultValueUpdate}
        label="Result Value"
        status={getRequiredInputStatus(resultValue)}
      />
    </>
  );
}

const tipStyles = css`
  grid-area: tip;
  margin-bottom: 16px;
`;
