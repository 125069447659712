import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { Button, DragAndDrop, Drawer, Droppable } from '@cimpress/react-components';

import { CONDITION_CONFIGURATIONS } from '../../constants/conditionConfigurations';
import {
  addBlankConditionGroup,
  deleteConditionGroupById,
  deleteDefaultAction,
  updateConditions,
  updateDefaultAction,
  updateNodeMetadata,
  updateOrderOfConditionGroupIds,
} from '../../features/selectedNode/selectedNodeSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  Action,
  DisplayValue,
  IfThenRuleConditionWithId,
  WorkingIfThen,
  WorkingRoutingConfigurationNode,
  WorkingRoutingConfigurationV3,
} from '../../types';
import { newBlankIfThen } from '../../utils/newBlankIfThen';
import { deleteNode, disconnectNode, getValidDownstreamNodes } from '../../utils/nodeUtils';
import AddElementButton from '../AddElementButton';
import CollapsibleWrapper from '../CollapsibleWrapper';
import DraggableElement from '../DraggableElement';
import NextNodeWrapperTooltip from '../NextNodeWrapperTooltip';
import ActionSelection from '../actionSelection/ActionSelection';
import ConditionGroup from '../conditionGroup/ConditionGroup';
import DefaultNextNodeSelect from '../styledComponents/DefaultNextNodeSelect';
import NothingConfigured from '../styledComponents/NothingConfigured';
import StyledTextField from '../styledComponents/StyledTextField';
import TrashIconButton from '../styledComponents/TrashIconButton';
import ConfirmCloseModal from './ConfirmCloseModal';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import SidebarFooter from './SidebarFooter';
import styles from './nodeSidebar.module.scss';

export default function NodeSidebar({
  show,
  onSave,
  onCancel,
  selectedNode,
  nodeOptions,
  workingConfiguration,
  onSaveConfiguration,
  nodeIsReachable,
}: {
  show: boolean;
  onSave: (updatedNode: WorkingRoutingConfigurationNode, newStartingNode: boolean) => void;
  onCancel: () => void;
  selectedNode: WorkingRoutingConfigurationNode;
  nodeOptions: WorkingRoutingConfigurationNode[];
  workingConfiguration: WorkingRoutingConfigurationV3;
  onSaveConfiguration: (updatedConfiguration: WorkingRoutingConfigurationV3) => void;
  nodeIsReachable: boolean;
}) {
  const orderOfConditionGroups = useAppSelector(
    (state) => state.selectedNode.orderOfConditionGroups,
  );

  const conditionGroupMetadata = useAppSelector(
    (state) => state.selectedNode.conditionGroupsToMetadataMap,
  );

  const conditionGroupIds = Object.keys(conditionGroupMetadata);

  const conditionGroupIdsToConditionIds = useAppSelector(
    (state) => state.selectedNode.conditionGroupsToConditionIdsMap,
  );

  const conditionGroupIdsToActionIds = useAppSelector(
    (state) => state.selectedNode.conditionGroupsToActionIdsMap,
  );

  const conditionsById = useAppSelector((state) => state.selectedNode.conditionsById);
  const actionsById = useAppSelector((state) => state.selectedNode.actionsById);

  const nodeMetadata = useAppSelector((state) => state.selectedNode.metadata);
  const snapshot = useAppSelector((state) => state.selectedNode.snapshot);

  const defaultAction = useAppSelector((state) =>
    state.selectedNode.metadata.defaultActionId
      ? state.selectedNode.actionsById[state.selectedNode.metadata.defaultActionId]
      : undefined,
  );

  const dispatch = useAppDispatch();

  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [blankConditionGroupIndexes, setBlankConditionGroupIndexes] = useState<number[]>([]);

  const [openAccordions, setOpenAccordions] = useState<string[]>([]);
  const canSave = useAppSelector((state) => state.selectedNode.canSave);

  const handleDefaultActionUpdate = (updatedAction: Action) => {
    dispatch(updateDefaultAction(updatedAction));
  };

  const handleDefaultActionDelete = () => {
    dispatch(deleteDefaultAction());
  };

  const deleteConditionGroupByIndex = (index: number) => {
    dispatch(deleteConditionGroupById(orderOfConditionGroups[index]));
  };

  const handleAccordionClick = (ifThenId: string) => {
    if (_.includes(openAccordions, ifThenId)) {
      setOpenAccordions((previousState) => _.filter(previousState, (id) => id !== ifThenId));
    } else {
      setOpenAccordions((previousState) => [...previousState, ifThenId]);
    }
  };

  const nodeOptionsForSelect = _.map(
    getValidDownstreamNodes(nodeOptions, selectedNode?.id),
    (node) => ({
      value: node.id,
      label: node.name,
    }),
  );

  const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

  const addIfThen = () => {
    const newIfThen = newBlankIfThen();
    const nextIndex = blankConditionGroupIndexes.length
      ? _.last(blankConditionGroupIndexes)! + 1
      : 1;
    newIfThen.name = `New Condition Group ${nextIndex}`;
    setBlankConditionGroupIndexes((previousState) => [...previousState, nextIndex]);
    dispatch(addBlankConditionGroup(newIfThen));
  };

  const handleDiscardChanges = () => {
    setShowDiscardModal(false);
    onCancel();
  };

  const handleSetNextNode = (nextNodeSelection?: DisplayValue<string>) => {
    const nextNodeWithId = nextNodeSelection?.value
      ? _.find(nodeOptions, { id: nextNodeSelection.value })
      : undefined;
    dispatch(
      updateNodeMetadata({
        ...nodeMetadata,
        defaultNextNodeId: nextNodeWithId?.id ?? undefined,
      }),
    );
  };

  const handleNodeNameUpdate = (e) => {
    dispatch(
      updateNodeMetadata({
        ...nodeMetadata,
        name: e.target.value,
      }),
    );
  };

  const handleNodeDescriptionUpdate = (e) => {
    dispatch(
      updateNodeMetadata({
        ...nodeMetadata,
        description: e.target.value,
      }),
    );
  };

  const handleSave = () => {
    const updatedConditions: IfThenRuleConditionWithId[] = [];
    // Ensure integer values are actually integers and not decimals
    _.forEach(Object.values(conditionGroupIdsToConditionIds), (conditionIds) => {
      _.forEach(conditionIds, (conditionId) => {
        const condition = conditionsById[conditionId]!;
        if (condition.customSource) {
          return;
        }
        const matchingConfig = _.find(CONDITION_CONFIGURATIONS, (config) => {
          if (condition.path !== '') {
            return config.path === condition.path;
          }
          // The only time we should ever do this is for option-related conditions
          return config.parameterIdentifier.path === condition.value.path;
        });

        if (matchingConfig?.shouldTruncateNumberValue) {
          updatedConditions.push({
            ...condition,
            value: Math.trunc(condition.value),
          });
        }
        if (updatedConditions.length) {
          dispatch(updateConditions(updatedConditions));
        }
      });
    });

    const saveAsNewStartingNode = !snapshot.metadata.isStartingNode && nodeMetadata.isStartingNode!;

    const updatedWorkingNode: WorkingRoutingConfigurationNode = {
      id: selectedNode.id,
      name: nodeMetadata.name!,
      description: nodeMetadata.description,
      defaultNextNodeId: nodeMetadata.defaultNextNodeId,
      ifThens: _.map(conditionGroupIds, (conditionGroupId) => {
        const ifThen: WorkingIfThen = {
          id: conditionGroupId,
          name: conditionGroupMetadata[conditionGroupId].name,
          description: conditionGroupMetadata[conditionGroupId].description,
          rule: {
            conditions: {
              [conditionGroupMetadata[conditionGroupId].joiner]: _.map(
                conditionGroupIdsToConditionIds[conditionGroupId],
                (conditionId) => {
                  return conditionsById[conditionId]!;
                },
              ),
            },
          },
          action: actionsById[conditionGroupIdsToActionIds[conditionGroupId]],
          nextNodeId: conditionGroupMetadata[conditionGroupId].nextNodeId,
        };
        return ifThen;
      }),
      defaultAction: defaultAction,
      defaultActionId: defaultAction?.id,
    };
    onSave(
      {
        ...updatedWorkingNode!,
      },
      saveAsNewStartingNode,
    );
  };

  const findNextNodeNameFromId = (nextNodeId?: string) => {
    const nextNode = _.find(nodeOptions, (node) => node.id === nextNodeId);
    return nextNode?.name ?? '';
  };

  const move = (droppableSource, droppableDestination) => {
    const sourceClone = [...orderOfConditionGroups];

    const [removed] = sourceClone.splice(droppableSource.index, 1);

    sourceClone.splice(droppableDestination.index, 0, removed);

    return sourceClone;
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const newResult = move(source, destination);
    dispatch(updateOrderOfConditionGroupIds(newResult));
  };

  const conditionsOrNothing = useMemo(
    () =>
      _.map(orderOfConditionGroups ?? [], (id: string, index: number) => {
        const metadata = conditionGroupMetadata[id];
        return metadata ? (
          <DraggableElement key={`draggable-${id}`} parentKey={id} index={index}>
            <CollapsibleWrapper
              key={`collapsible-${id}`}
              title={
                <div className={styles.ifThenContainer} key={`flexdiv-${id}`}>
                  <span>{metadata.name}</span>
                  <TrashIconButton onDelete={() => deleteConditionGroupByIndex(index)} />
                </div>
              }
              isOpen={_.includes(openAccordions, id)}
              onAccordionClick={handleAccordionClick}
            >
              <ConditionGroup nodeOptions={nodeOptionsForSelect} conditionGroupId={id} />
            </CollapsibleWrapper>
          </DraggableElement>
        ) : (
          <></>
        );
      }),
    [orderOfConditionGroups, JSON.stringify(conditionGroupMetadata)],
  );

  const handleCloseSidebar = () => {
    if (canSave) {
      setShowDiscardModal(true);
    } else {
      handleDiscardChanges();
    }
  };

  const handleDeleteButtonPress = () => {
    setShowDeleteModal(true);
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
  };

  const handleConfirmDelete = () => {
    const clonedConfiguration = _.cloneDeep(workingConfiguration);
    nodeIsReachable
      ? disconnectNode({
          rc: clonedConfiguration,
          nodeId: selectedNode!.id,
        })
      : deleteNode({
          rc: clonedConfiguration,
          nodeId: selectedNode!.id,
        });

    onSaveConfiguration(clonedConfiguration);
    setShowDeleteModal(false);
  };

  const Header = (
    <h2 className={styles.header}>
      <span>Edit Node</span>
    </h2>
  );

  return (
    <div className={styles.container}>
      <ErrorBoundary fallback={<p>Error occurred in Node Sidebar</p>}>
        <Drawer
          zIndex={5}
          size={viewportWidth > 1400 ? 0.5 : 1.0}
          show={show}
          header={Header}
          footer={
            <SidebarFooter
              onSave={handleSave}
              onCancel={handleCloseSidebar}
              onDelete={handleDeleteButtonPress}
              nodeIsReachable={nodeIsReachable}
              enabled={canSave}
            />
          }
          onRequestHide={handleCloseSidebar}
        >
          <div>
            <ConfirmCloseModal
              showModal={showDiscardModal}
              onRequestHide={() => setShowDiscardModal(false)}
              onConfirmCancel={handleDiscardChanges}
            />
            <ConfirmDeleteModal
              showModal={showDeleteModal}
              onRequestHide={handleDeleteCancel}
              onConfirmDelete={handleConfirmDelete}
              nodeIsReachable={nodeIsReachable}
            />

            <div>
              <div className={styles.flexRow}>
                <StyledTextField
                  size="lg"
                  name="nodeNameInput"
                  value={nodeMetadata.name}
                  label="Node Name"
                  onChange={handleNodeNameUpdate}
                  type="text"
                  required
                />
                <StyledTextField
                  size="lg"
                  name="nodeDescriptionInput"
                  value={nodeMetadata.description}
                  label="Node Description"
                  onChange={handleNodeDescriptionUpdate}
                  type="text"
                />
              </div>
              <div className={styles.flexRow}>
                <NextNodeWrapperTooltip enabled={!nodeOptionsForSelect.length}>
                  <DefaultNextNodeSelect
                    label="Default next node"
                    value={{
                      value: nodeMetadata.defaultNextNodeId,
                      label: findNextNodeNameFromId(nodeMetadata.defaultNextNodeId),
                    }}
                    options={nodeOptionsForSelect}
                    onChange={handleSetNextNode}
                    menuPlacement="top"
                    isDisabled={!nodeOptionsForSelect.length}
                    isClearable
                  />
                </NextNodeWrapperTooltip>
              </div>
              {!snapshot.metadata.isStartingNode && nodeMetadata.isStartingNode && (
                <Button
                  className={styles.setStartingNode}
                  onClick={() =>
                    dispatch(updateNodeMetadata({ ...nodeMetadata, isStartingNode: false }))
                  }
                  variant="primary"
                >
                  Unset Node as starting node
                </Button>
              )}
              {!nodeMetadata.isStartingNode && (
                <Button
                  className={styles.setStartingNode}
                  onClick={() =>
                    dispatch(updateNodeMetadata({ ...nodeMetadata, isStartingNode: true }))
                  }
                >
                  Set Node as starting node
                </Button>
              )}
              {!snapshot.metadata.isStartingNode && nodeMetadata.isStartingNode && (
                <p className={styles.isStartingNodeWarning}>
                  This Node has been set as the starting node; if changes are saved, preceding Nodes
                  will become unassigned.
                </p>
              )}
            </div>

            {orderOfConditionGroups.length > 0 ? (
              <DragAndDrop onDragEnd={onDragEnd}>
                <Droppable droppableId="ifThens" isEmpty={!conditionGroupIds.length}>
                  {conditionsOrNothing}
                </Droppable>
              </DragAndDrop>
            ) : (
              <NothingConfigured>
                No condition groups have been added yet. This node will apply the default action if
                one is present, and pass options to the next node.
              </NothingConfigured>
            )}
            <AddElementButton
              onClick={addIfThen}
              text="Add condition group"
              className={styles.button}
            />
          </div>
          <p className={styles.then}>Default node action</p>
          <ActionSelection
            action={
              nodeMetadata.defaultActionId ? actionsById[nodeMetadata.defaultActionId] : undefined
            }
            onActionUpdate={handleDefaultActionUpdate}
            onDelete={handleDefaultActionDelete}
            noActionConfiguredText={`No default action is configured; 
          if no condition groups are passed, then fulfillment options will 
          automatically pass to the next node.`}
          />
        </Drawer>
      </ErrorBoundary>
    </div>
  );
}
