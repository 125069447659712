import _ from 'lodash';
import React from 'react';

import PlusIconButton from '../../styledComponents/PlusIconButton';
import StyledTextField from '../../styledComponents/StyledTextField';
import Tip from '../../styledComponents/Tip';
import TrashIconButton from '../../styledComponents/TrashIconButton';
import styles from './orderItems.module.scss';

export type OrderItem = {
  orderedSkuCode?: string;
  skuCode?: string;
  fulfillerId?: string;
};

export default function OrderItems({
  orderItems,
  setOrderItems,
  isValid,
  setIsValid,
}: {
  orderItems: OrderItem[];
  setOrderItems: (orderItems: OrderItem[]) => void;
  isValid: boolean;
  setIsValid: (valid: boolean) => void;
}) {
  const deleteRow = (index: number) => {
    const clonedItems = [...orderItems];
    clonedItems.splice(index, 1);
    setOrderItems(clonedItems);
    setIsValid(!shouldDisablePlusButton(clonedItems));
  };

  const addRow = () => {
    const clonedItems = [...orderItems, {}];
    setIsValid(false);
    setOrderItems(clonedItems);
  };

  const updateAtIndex = (item: OrderItem, index: number) => {
    const clonedItems = [...orderItems];
    clonedItems[index] = item;
    setOrderItems(clonedItems);
    setIsValid(!shouldDisablePlusButton(clonedItems));
  };

  const shouldDisablePlusButton = (items: OrderItem[]) => {
    if (!items.length) {
      return false;
    }

    return !_.every(items, (item) => !!item.orderedSkuCode);
  };

  return (
    <div>
      <div>
        {orderItems.map((item, index) => (
          <OrderItemRow
            key={`ordered-items-${index}`}
            {...item}
            onDelete={() => deleteRow(index)}
            onChange={(i) => updateAtIndex(i, index)}
            isInvalid={!item.orderedSkuCode}
          />
        ))}
      </div>
      {!isValid && <Tip className={styles.marginBottom}>Ordered SKU is required.</Tip>}
      <PlusIconButton onClick={addRow} disabled={shouldDisablePlusButton(orderItems)} />
    </div>
  );
}

function OrderItemRow({
  orderedSkuCode,
  skuCode,
  fulfillerId,
  onDelete,
  onChange,
  isInvalid,
}: OrderItem & {
  onDelete: (index: number) => void;
  onChange: (item: OrderItem) => void;
  isInvalid: boolean;
}) {
  const onChangeOrderedSku = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange({
      orderedSkuCode: e.target.value,
      skuCode,
      fulfillerId,
    });
  };

  const onChangeRoutedSku = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange({
      orderedSkuCode,
      skuCode: e.target.value,
      fulfillerId,
    });
  };

  const onChangeFulfillerId = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange({
      orderedSkuCode,
      skuCode,
      fulfillerId: e.target.value,
    });
  };

  return (
    <div className={styles.row}>
      <div className={styles.rowFields}>
        <StyledTextField
          size="sm"
          value={orderedSkuCode}
          label="Ordered SKU"
          onChange={onChangeOrderedSku}
          status={isInvalid ? 'error' : 'success'}
        />
        <StyledTextField
          size="sm"
          value={skuCode}
          label="Routed SKU"
          onChange={onChangeRoutedSku}
          status={isInvalid || !skuCode ? undefined : 'success'}
        />
        <StyledTextField
          size="sm"
          value={fulfillerId}
          label="Fulfiller ID"
          onChange={onChangeFulfillerId}
          status={isInvalid || !fulfillerId ? undefined : 'success'}
        />
      </div>
      <TrashIconButton className={styles.deleteButton} onDelete={onDelete} />
    </div>
  );
}
