import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { RawNodeDatum } from 'react-d3-tree';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Spinner } from '@cimpress/react-components';

import { IfThenContext } from '../context/IfThenContext';
import { clearAlert, setError, setWarning } from '../features/alerts/alertContentSlice';
import {
  setBaseWorkingConfigurationSnapshot,
  setSelectedNode,
  setWorkingConfiguration,
} from '../features/selectedConfiguration/selectedConfigurationSlice';
import {
  clearSelectedNode,
  setStateFromExistingNode,
} from '../features/selectedNode/selectedNodeSlice';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import {
  DisplayValue,
  WorkingRoutingConfigurationNode,
  WorkingRoutingConfigurationV3,
} from '../types';
import { buildTreeAndIdentifyUnreachableNodes } from '../utils/buildTreeAndIdentifyUnreachableNodes';
import { removeDanglingIfThenReferences } from '../utils/conversions';
import { hydrateRoutingConfiguration } from '../utils/hydrate';
import { removeOutgoingNodeReferences } from '../utils/nodeUtils';
import { AppAlert } from './AppAlert';
import CloneToEnvironmentButton from './CloneToEnvironmentButton';
import PublishButton from './PublishButton';
import styles from './editRoutingConfigurationPage.module.scss';
import NodeSidebar from './nodeSidebar/NodeSidebar';
import NodeTreeContainer from './nodeTree/NodeTreeContainer';
import UnassignedNodeContainer from './unreachableNodes/UnassignedNodeContainer';

export default function EditRoutingConfigurationPage() {
  const navigate = useNavigate();
  const { accountId } = useParams();
  const { pathname } = useLocation();

  const selectedConfiguration = useAppSelector(
    (state) => state.selectedConfiguration.routingConfiguration,
  );
  const workingConfiguration = useAppSelector(
    (state) => state.selectedConfiguration.workingConfiguration,
  );

  const selectedNode = useAppSelector((state) => state.selectedConfiguration.selectedNode);

  const dispatch = useAppDispatch();

  const dispatchWorkingConfiguration = (rc: WorkingRoutingConfigurationV3 | null) =>
    dispatch(setWorkingConfiguration(rc));
  const dispatchSelectedNode = (node: WorkingRoutingConfigurationNode | null) =>
    dispatch(setSelectedNode(node));
  const dispatchErrors = ({ messages, title }: { messages: string[]; title: string }) =>
    dispatch(setError({ messages, title }));

  // Synchronize all components with changes to ifThen names or IDs
  const { setIfThenMap } = useContext(IfThenContext);

  const [showSidebar, setShowSidebar] = useState(false);

  const [unreachableNodes, setUnreachableNodes] = useState<WorkingRoutingConfigurationNode[]>([]);
  const [tree, setTree] = useState<RawNodeDatum>({
    attributes: { id: '' },
    name: '',
  } as RawNodeDatum);

  useEffect(() => {
    const snapshot = hydrateRoutingConfiguration(selectedConfiguration);
    // Prevent these from sharing the same reference
    dispatchWorkingConfiguration(snapshot);
    dispatch(setBaseWorkingConfigurationSnapshot(_.cloneDeep(snapshot)));
  }, [JSON.stringify(selectedConfiguration)]);

  useEffect(() => {
    if (workingConfiguration) {
      const clonedConfiguration = _.cloneDeep(workingConfiguration);
      try {
        const {
          tree: _tree,
          unreachableNodes: _unreachableNodes,
          nodeIdsNotFound,
        } = buildTreeAndIdentifyUnreachableNodes(clonedConfiguration);
        setTree(_tree);

        if (nodeIdsNotFound.length) {
          dispatch(
            setWarning({
              title: 'Expected node IDs not found',
              messages: [
                'Node IDs referenced in the routing configuration could not be found:',
                nodeIdsNotFound.join(', '),
              ],
            }),
          );
        }

        _.forEach(_unreachableNodes, (node) => removeOutgoingNodeReferences(node));
        setUnreachableNodes(_unreachableNodes);

        const updatedIfThenMap = _.reduce(
          clonedConfiguration.nodes,
          (ifThens, node) => {
            _.forEach(node.ifThens, (ifThen) => {
              ifThens[ifThen.id] = {
                label: ifThen.name || ifThen.id,
                value: ifThen.id,
              };
            });
            return ifThens;
          },
          [] as Record<string, DisplayValue<string>>[],
        );

        setIfThenMap(updatedIfThenMap);
        removeDanglingIfThenReferences(clonedConfiguration, Object.keys(updatedIfThenMap));
        dispatchWorkingConfiguration(clonedConfiguration);
      } catch (e: any) {
        dispatchErrors({
          title: 'An error occurred while building the Routing Configuration',
          messages: [e.message],
        });
      }
    }
  }, [JSON.stringify(workingConfiguration)]);

  const selectNodeObjectAndOpenSidebar = (node: WorkingRoutingConfigurationNode) => {
    if (!workingConfiguration) {
      return;
    }

    dispatchSelectedNode(node);
    dispatch(
      setStateFromExistingNode({
        node,
        isStartingNode: node.id === workingConfiguration.startingNodeId,
      }),
    );
    setShowSidebar(true);
    dispatch(clearAlert());
  };

  const selectCanvasNodeAndOpenSidebar = (element: HTMLElement) => {
    if (!workingConfiguration) {
      return;
    }
    const node = _.find(
      workingConfiguration.nodes,
      (n) => n.id === element.getAttribute('data-id'),
    )!;

    dispatchSelectedNode(node);
    dispatch(
      setStateFromExistingNode({
        node,
        isStartingNode: node.id === workingConfiguration.startingNodeId,
      }),
    );
    setShowSidebar(true);
    dispatch(clearAlert());
  };

  const onSaveNode = (nodeToUpdate: WorkingRoutingConfigurationNode, newStartingNode: boolean) => {
    if (!workingConfiguration) {
      return;
    }

    const nodes = _.map(workingConfiguration.nodes, (node) => {
      if (node.id === nodeToUpdate.id) {
        return nodeToUpdate;
      }
      return node;
    });

    dispatchWorkingConfiguration({
      ...workingConfiguration,
      nodes,
      startingNodeId: newStartingNode ? nodeToUpdate.id : workingConfiguration.startingNodeId,
    });
    dispatchSelectedNode(null);
    dispatch(clearSelectedNode());
    setShowSidebar(false);
  };

  const handleDiscardChanges = () => {
    setShowSidebar(false);
    dispatchSelectedNode(null);
    dispatch(clearSelectedNode());
  };

  const onPublishSuccessful = (configuration: WorkingRoutingConfigurationV3) => {
    setShowSidebar(false);
    dispatchSelectedNode(null);
    if (pathname.includes('create')) {
      // Navigate to edit route if required
      navigate(`/accounts/${accountId}/configurations/edit/${configuration.id}`);
    }
  };

  const publishButton = <PublishButton onPublishSuccessful={onPublishSuccessful} />;

  const updateWorkingConfigurationAfterNodeDelete = (
    updatedConfig: WorkingRoutingConfigurationV3,
  ) => {
    dispatchWorkingConfiguration(updatedConfig);
    dispatchSelectedNode(null);
    setShowSidebar(false);
  };

  return (
    <div>
      <AppAlert />
      {selectedConfiguration ? (
        <div className={styles.container}>
          <NodeSidebar
            show={showSidebar}
            onCancel={handleDiscardChanges}
            onSave={onSaveNode}
            selectedNode={selectedNode!}
            nodeOptions={workingConfiguration?.nodes ?? []}
            workingConfiguration={workingConfiguration!}
            onSaveConfiguration={updateWorkingConfigurationAfterNodeDelete}
            nodeIsReachable={!_.includes(unreachableNodes, selectedNode)}
          />
          <div className={styles.sidebar}>
            <NodeTreeContainer
              onNodeClick={selectCanvasNodeAndOpenSidebar}
              workingConfiguration={workingConfiguration!}
              setWorkingConfiguration={dispatchWorkingConfiguration}
              publishButton={publishButton}
              tree={tree}
            />
            <div className={styles.rightSide}>
              <UnassignedNodeContainer
                nodes={unreachableNodes}
                onClick={selectNodeObjectAndOpenSidebar}
              />
              <CloneToEnvironmentButton />
            </div>
          </div>
        </div>
      ) : (
        <Spinner fullPage={true} />
      )}
    </div>
  );
}
