/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import { Button, Modal } from '@cimpress/react-components';

export default function ConfirmCloneModal({
  showModal,
  onRequestHide,
  onConfirmClone,
}: {
  showModal: boolean;
  onRequestHide: () => void;
  onConfirmClone: () => void;
}) {
  const footer = (
    <div css={horizontalFlexStyles}>
      <Button variant="secondary" css={buttonStyles} onClick={onConfirmClone}>
        Clone
      </Button>
      <Button variant="primary" onClick={onRequestHide}>
        Cancel
      </Button>
    </div>
  );
  return (
    <Modal
      show={showModal}
      closeButton={true}
      onRequestHide={onRequestHide}
      title={'Clone Configuration'}
      footer={footer}
    >
      <p>Are you sure you want to clone this configuration?</p>
      <p css={topMargin}>
        This configuration will be cloned to the{' '}
        <strong>
          {process.env.REACT_APP_ENVIRONMENT_NAME === 'Production' ? 'Integration' : 'Production'}{' '}
        </strong>
        environment. It will replace any existing configuration there.
      </p>
    </Modal>
  );
}

const horizontalFlexStyles = css`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const buttonStyles = css`
  margin-right: 16px;
`;

const topMargin = css`
  margin-top: 10px;
`;
