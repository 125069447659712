/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import _ from 'lodash';
import React, { memo, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { Button, colors } from '@cimpress/react-components';

import { DEFAULT_INPUT_HEIGHT, SMALL_FONT } from '../../constants/stylingConstants';
import * as NodeActions from '../../features/selectedNode/selectedNodeSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { Action, DisplayValue, IfThenRuleConditionWithId } from '../../types';
import { getFactFromIfThenRuleCondition } from '../../utils/conversions';
import AddElementButton from '../AddElementButton';
import NextNodeWrapperTooltip from '../NextNodeWrapperTooltip';
import ActionSelection from '../actionSelection/ActionSelection';
import ConditionGroupNextNodeSelect from '../styledComponents/ConditionGroupNextNodeSelect';
import HorizontalRule from '../styledComponents/HorizontalRule';
import NothingConfigured from '../styledComponents/NothingConfigured';
import StyledTextField from '../styledComponents/StyledTextField';
import Condition from './Condition';
import ConditionJoiner from './ConditionJoiner';

type ConditionGroupProps = {
  nodeOptions: DisplayValue<string>[];
  conditionGroupId: string;
};

// corresponds to a single IfThen
export default memo(function ConditionGroup({
  nodeOptions,
  conditionGroupId,
}: ConditionGroupProps) {
  const conditionGroups = useAppSelector(
    (state) => state.selectedNode.conditionGroupsToMetadataMap,
  );
  const ifThenIdsWithinNode = Object.keys(conditionGroups);
  const conditionIdsInGroup = useAppSelector(
    (state) => state.selectedNode.conditionGroupsToConditionIdsMap[conditionGroupId],
  );

  const { joiner, name, description, nextNodeId, actionId } = useAppSelector((state) => {
    return state.selectedNode.conditionGroupsToMetadataMap[conditionGroupId];
  });

  const action = useAppSelector(
    (state) =>
      state.selectedNode.actionsById[
        state.selectedNode.conditionGroupsToActionIdsMap[conditionGroupId]
      ],
  );
  const dispatch = useAppDispatch();

  const [key, setKey] = useState<string>(uuid());

  const nextNode = nextNodeId
    ? _.find(nodeOptions, (node) => node.value === nextNodeId)
    : undefined;

  const handleSelectNextNode = (nextNodeSelection?: DisplayValue<string>) => {
    const nextNodeWithId: DisplayValue<string> | undefined = nextNodeSelection?.value
      ? _.find(nodeOptions, (opt) => opt.value === nextNodeSelection.value)
      : undefined;

    dispatch(
      NodeActions.updateConditionGroupMetadata({
        conditionGroupId,
        metadata: {
          id: conditionGroupId,
          joiner,
          name,
          description,
          nextNodeId: nextNodeWithId?.value,
        },
      }),
    );
  };

  const addCondition = () => {
    dispatch(NodeActions.addConditionToConditionGroupId({ conditionGroupId, conditionId: uuid() }));
  };

  const deleteConditionByIndex = (index: number) => {
    const conditionId = conditionIdsInGroup[index];
    dispatch(NodeActions.deleteCondition(conditionId));
  };

  const handleConditionChange = (updatedCondition: IfThenRuleConditionWithId) => {
    const fact = getFactFromIfThenRuleCondition(updatedCondition);
    updatedCondition.fact = fact ?? updatedCondition.fact;

    dispatch(NodeActions.updateConditions([updatedCondition]));
    setKey(uuid());
  };

  const handleConditionJoinerChange = (conditionJoiner: 'all' | 'any') => {
    dispatch(
      NodeActions.updateConditionGroupMetadata({
        conditionGroupId,
        metadata: { id: conditionGroupId, joiner: conditionJoiner, name, description, nextNodeId },
      }),
    );
    setKey(uuid());
  };

  const handleIfThenNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      NodeActions.updateConditionGroupMetadata({
        conditionGroupId,
        metadata: {
          id: conditionGroupId,
          joiner,
          name: e.target.value,
          description,
          nextNodeId,
        },
      }),
    );
    setKey(uuid());
  };

  const handleIfThenDescriptionChange = (e: any) => {
    dispatch(
      NodeActions.updateConditionGroupMetadata({
        conditionGroupId,
        metadata: {
          id: conditionGroupId,
          joiner,
          name,
          description: e.target.value,
          nextNodeId,
        },
      }),
    );
    setKey(uuid());
  };

  const handleActionUpdate = (updatedAction: Action) => {
    if (updatedAction) {
      dispatch(
        NodeActions.updateActionForConditionGroupId({ conditionGroupId, action: updatedAction }),
      );
    }
  };

  const handleActionDelete = () => {
    dispatch(NodeActions.deleteActionForConditionGroupId(conditionGroupId));
  };

  const conditions = useMemo(
    () =>
      _.map(conditionIdsInGroup || [], (conditionId: string, index: number) => (
        <div key={`condition-wrapper-${key}-${index}`}>
          {index > 0 && (
            <div>
              <ConditionJoiner
                joiner={joiner}
                setJoiner={handleConditionJoinerChange}
                enabled={index === 1}
              />
            </div>
          )}
          <Condition
            key={`condition-${key}-${index}`}
            conditionKey={`condition-${key}-${index}`}
            conditionId={conditionId}
            ifThenIdsWithinNode={ifThenIdsWithinNode}
            onConditionUpdate={(updatedCondition) => handleConditionChange(updatedCondition)}
            onDelete={() => deleteConditionByIndex(index)}
          />
        </div>
      )),
    [
      JSON.stringify(conditionIdsInGroup),
      JSON.stringify(ifThenIdsWithinNode),
      actionId,
      nextNodeId,
      joiner,
    ],
  );

  return (
    <div css={conditionGroupStyles} key={conditionGroupId}>
      <div css={flexDivFlexStart}>
        <div>
          <StyledTextField
            name="ifThenName"
            value={name}
            onChange={handleIfThenNameChange}
            label="Condition group name"
          />
        </div>
        <StyledTextField
          size="lg"
          name="ifThenDescription"
          value={description}
          onChange={handleIfThenDescriptionChange}
          label="Description"
        />
      </div>
      <p css={leadInStyles}>IF...</p>
      {conditions}
      <AddElementButton onClick={addCondition} text="Add condition" />
      {!conditions?.length && (
        <NothingConfigured>
          This condition group has no conditions; it will automatically apply its action and pass
          options to the next node.
        </NothingConfigured>
      )}

      <HorizontalRule />

      <p css={thenStyles}>THEN...</p>
      <ActionSelection
        action={action}
        onActionUpdate={(a) => handleActionUpdate(a)}
        onDelete={handleActionDelete}
        noActionConfiguredText={`This condition group has no action configured; if it passes, 
        it will pass options to the next node.`}
      />

      <div css={nextNodeContainer}>
        <NextNodeWrapperTooltip enabled={!nodeOptions.length}>
          <ConditionGroupNextNodeSelect
            label="Next Node"
            value={nextNode}
            options={nodeOptions}
            onChange={handleSelectNextNode}
            isDisabled={!nodeOptions.length}
            isClearable
          />
        </NextNodeWrapperTooltip>
        <Button
          css={removeButtonStyles}
          onClick={() => dispatch(NodeActions.deleteConditionGroupById(conditionGroupId))}
        >
          Remove Condition Group
        </Button>
      </div>
    </div>
  );
});

const flexDivFlexStart = css`
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 16px;
`;

const leadInStyles = css`
  color: ${colors.shale};
  font-size: ${SMALL_FONT};
  margin-bottom: 4px;
`;

const conditionGroupStyles = css`
  border: 1px solid ${colors.slate};
  border-radius: 4px;
  padding: 12px;
  position: relative;
`;

const nextNodeContainer = css`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;

  @media (max-width: 600px) {
    // Make room for the remove condition group button
    margin-bottom: 40px;
  }
`;

const removeButtonStyles = css`
  position: absolute;
  bottom: 10px;
  right: 10px;
  height: ${DEFAULT_INPUT_HEIGHT};
  font-size: 1.15em;
`;

const thenStyles = css`
  color: ${colors.shale};
  font-size: ${SMALL_FONT};
`;
