/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import _ from 'lodash';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Button } from '@cimpress/react-components';

import { clearAlert, setError, setSuccess } from '../features/alerts/alertContentSlice';
import {
  createRoutingConfiguration,
  getRoutingConfigurationByBuyerAndSku,
  getRoutingConfigurationById,
  updateRoutingConfiguration,
} from '../services/routingConfigurationService';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { RoutingConfigurationPostBody, RoutingConfigurationPutBody } from '../types';
import { convertWorkingConfigToDbConfig } from '../utils/conversions';
import { formatHttpErrorMessage } from '../utils/formatHttpErrorMessage';
import ConfirmCloneModal from './ConfirmCloneModal';

const overrideRoutingConfigBaseUrl = process.env
  .REACT_APP_ROUTING_CONFIGURATION_CLONE_URL as string;

export default function CloneToEnvironmentButton() {
  const referenceId = useAppSelector((state) => state.selectedConfiguration.skuCode)!;
  const baseConfiguration = useAppSelector(
    (state) => state.selectedConfiguration.routingConfiguration,
  )!;
  const workingConfiguration = useAppSelector(
    (state) => state.selectedConfiguration.workingConfiguration,
  )!;

  const { accountId } = useParams();
  const isDefault = _.toLower(baseConfiguration.id) === 'default';

  const canClone = !isDefault && baseConfiguration.id;

  const [isFetching, setIsFetching] = useState(false);
  const [showCloneConfirmationModal, setShowCloneConfirmationModal] = useState(false);

  const dispatch = useAppDispatch();
  const dispatchErrors = ({ messages, title }: { messages: string[]; title: string }) =>
    dispatch(setError({ messages, title }));
  const dispatchSuccess = ({ title, messages }: { title: string; messages: string }) =>
    dispatch(setSuccess({ title, messages }));
  const dispatchClearAlert = () => dispatch(clearAlert());

  const handleCloneButtonPress = () => {
    setShowCloneConfirmationModal(true);
  };

  const handleCloneConfirmationCancel = () => {
    setShowCloneConfirmationModal(false);
  };

  const handleCloneConfig = async () => {
    setIsFetching(true);
    dispatchClearAlert();
    const formattedConfiguration = convertWorkingConfigToDbConfig({
      workingConfiguration,
      baseConfiguration,
    });
    const httpBody: Record<string, any> = _.pick(formattedConfiguration, [
      'description',
      'startingNodeId',
      'ifThens',
      'actions',
      'nodes',
      'isDraft',
      'selectionStrategy',
      'nativeV3',
    ]);

    let clonedId: string;
    try {
      const { configuration: existingConfiguration } = await getRoutingConfigurationByBuyerAndSku({
        buyer: accountId as string,
        skuCode: referenceId,
        overrideRoutingConfigBaseUrl,
      });

      if (existingConfiguration.id === 'default') {
        httpBody.accountId = accountId;
        httpBody.referenceId = referenceId;
        const createdConfig = await createRoutingConfiguration(
          httpBody as RoutingConfigurationPostBody,
          overrideRoutingConfigBaseUrl,
        );
        clonedId = createdConfig.configuration.id;
      } else {
        const { etag } = await getRoutingConfigurationById(
          existingConfiguration.id,
          overrideRoutingConfigBaseUrl,
        );
        const updatedConfig = await updateRoutingConfiguration({
          id: existingConfiguration.id,
          body: httpBody as RoutingConfigurationPutBody,
          entityTag: etag,
          overrideRoutingConfigBaseUrl,
        });
        clonedId = updatedConfig.configuration.id;
      }
      dispatchSuccess({
        title: 'Clone successful',
        messages: `Cloned configuration: ${process.env.REACT_APP_ROUTING_CONFIGURATION_UI_CLONE_URL}/accounts/${accountId}/configurations/edit/${clonedId}`,
      });
    } catch (e) {
      dispatchErrors({
        title: `An error occurred while cloning the Routing Configuration`,
        messages: formatHttpErrorMessage(e),
      });
    } finally {
      setIsFetching(false);
      setShowCloneConfirmationModal(false);
    }
  };

  return (
    <div css={entireButtonStyle}>
      <Button
        variant="primary"
        size="lg"
        css={buttonStyle}
        onClick={() => handleCloneButtonPress()}
        disabled={!canClone || isFetching}
      >
        Clone To{' '}
        {process.env.REACT_APP_ENVIRONMENT_NAME === 'Production' ? 'Integration' : 'Production'}
      </Button>
      <ConfirmCloneModal
        showModal={showCloneConfirmationModal}
        onRequestHide={handleCloneConfirmationCancel}
        onConfirmClone={handleCloneConfig}
      />
    </div>
  );
}

const entireButtonStyle = css`
  display: flex;
  justify-content: center;
`;

const buttonStyle = css`
  height: 48px;
`;
