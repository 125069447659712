/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import _ from 'lodash';
import React from 'react';

import { colors } from '@cimpress/react-components';

import { allowedDictionaryRanks } from '../../constants/allowedDictionaryRanks';
import { DEFAULT_LABEL, DEFAULT_VALUE } from '../../constants/constants';
import { DisplayValue } from '../../types';
import StyledTextField from '../styledComponents/StyledTextField';
import TrashIconButton from '../styledComponents/TrashIconButton';
import DictionarySelect from './DictionarySelect';

export default function RankedDictionaryRow({
  selectedKey,
  onChangeSelectedKey,
  selectedValue,
  onChangeSelectedValue,
  allowedKeys,
  onDelete,
  inputName,
  allowTextInput,
}: {
  selectedKey: DisplayValue<string>;
  onChangeSelectedKey: ({ value }) => void;
  selectedValue: DisplayValue<string | number>;
  onChangeSelectedValue: ({ value }) => void;
  allowedKeys: DisplayValue<string>[];
  onDelete: () => void;
  inputName: string;
  allowTextInput: boolean;
}) {
  const handleTextInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    onChangeSelectedKey({ value });
  };

  let textboxInputStyles = {};
  if (selectedKey?.value === DEFAULT_VALUE || selectedKey.label === DEFAULT_LABEL) {
    textboxInputStyles = defaultSelectedTextbox;
  }

  const optionAttributeSelect = allowTextInput ? (
    <StyledTextField
      label={`Enter ${inputName}`}
      value={selectedKey.value}
      onChange={handleTextInput}
      inputStyles={textboxInputStyles}
    />
  ) : (
    <DictionarySelect
      label={`Enter ${inputName}`}
      options={_.concat(allowedKeys, [selectedKey])}
      value={selectedKey}
      onChange={onChangeSelectedKey}
      classNamePrefix={'dictionary'}
      isDefault={selectedKey?.value === DEFAULT_VALUE}
    />
  );

  return (
    <>
      <div css={flexDiv}>
        {optionAttributeSelect}
        <DictionarySelect
          label="Priority"
          options={allowedDictionaryRanks}
          value={selectedValue}
          onChange={onChangeSelectedValue}
        />
        <TrashIconButton onDelete={onDelete} />
      </div>
    </>
  );
}

const flexDiv = css`
  display: flex;
  gap: 10px;
`;

const defaultSelectedTextbox = {
  backgroundColor: colors.sky.base,
};
