/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import _ from 'lodash';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Button } from '@cimpress/react-components';

import { clearAlert, setError, setSuccess } from '../features/alerts/alertContentSlice';
import {
  setEtag,
  setRoutingConfiguration,
  setWorkingConfiguration,
} from '../features/selectedConfiguration/selectedConfigurationSlice';
import {
  createRoutingConfiguration,
  updateRoutingConfiguration,
} from '../services/routingConfigurationService';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import {
  RoutingConfigurationPostBody,
  RoutingConfigurationPutBody,
  RoutingConfigurationV3,
  WorkingRoutingConfigurationV3,
} from '../types';
import { convertWorkingConfigToDbConfig } from '../utils/conversions';
import { formatHttpErrorMessage } from '../utils/formatHttpErrorMessage';
import { hydrateRoutingConfiguration } from '../utils/hydrate';

export default function PublishButton({
  onPublishSuccessful,
}: {
  onPublishSuccessful: (configuration: WorkingRoutingConfigurationV3) => void;
}) {
  const { accountId } = useParams();
  const entityTag = useAppSelector((state) => state.selectedConfiguration.etag);
  const referenceId = useAppSelector((state) => state.selectedConfiguration.skuCode);

  const baseConfiguration = useAppSelector(
    (state) => state.selectedConfiguration.routingConfiguration,
  )!;
  const baseConfigurationSnapshot = useAppSelector(
    (state) => state.selectedConfiguration.baseWorkingConfigurationSnapshot,
  );
  const workingConfiguration = useAppSelector(
    (state) => state.selectedConfiguration.workingConfiguration,
  )!;

  const canPublish = !_.isEqual(baseConfigurationSnapshot, workingConfiguration);

  const isDefault = _.toLower(baseConfiguration.id) === 'default';

  const [isFetching, setIsFetching] = useState(false);

  const dispatch = useAppDispatch();
  const dispatchErrors = ({ messages, title }: { messages: string[]; title: string }) =>
    dispatch(setError({ messages, title }));
  const dispatchSuccess = ({ title }: { title: string }) => dispatch(setSuccess({ title }));
  const dispatchClearAlert = () => dispatch(clearAlert());
  const dispatchSelectedConfiguration = (rc: RoutingConfigurationV3) =>
    dispatch(setRoutingConfiguration(rc));

  const publish = async () => {
    setIsFetching(true);
    dispatchClearAlert();
    const formattedConfiguration = convertWorkingConfigToDbConfig({
      workingConfiguration,
      baseConfiguration,
    });
    const httpBody: Record<string, any> = _.pick(formattedConfiguration, [
      'description',
      'startingNodeId',
      'ifThens',
      'actions',
      'nodes',
      'isDraft',
      'selectionStrategy',
      'nativeV3',
    ]);

    try {
      if (isDefault) {
        httpBody.accountId = accountId;
        httpBody.referenceId = referenceId;
        const { configuration, etag } = await createRoutingConfiguration(
          httpBody as RoutingConfigurationPostBody,
        );

        dispatch(setEtag(etag));
        dispatchSelectedConfiguration(configuration);
        const hydratedConfig = hydrateRoutingConfiguration(configuration)!;
        setWorkingConfiguration(hydratedConfig);
        onPublishSuccessful(hydratedConfig);
      } else {
        const { configuration, etag } = await updateRoutingConfiguration({
          id: formattedConfiguration.id,
          body: httpBody as RoutingConfigurationPutBody,
          entityTag,
        });

        dispatch(setEtag(etag));
        dispatchSelectedConfiguration(configuration);
        const hydratedConfig = hydrateRoutingConfiguration(configuration)!;
        setWorkingConfiguration(hydratedConfig);
        onPublishSuccessful(hydratedConfig);
      }
      dispatchSuccess({
        title: 'Publish successful',
      });
    } catch (e) {
      dispatchErrors({
        title: `An error occurred while publishing the Routing Configuration`,
        messages: formatHttpErrorMessage(e),
      });
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <Button
      variant="primary"
      size="lg"
      css={buttonStyle}
      onClick={() => publish()}
      disabled={!canPublish || isFetching}
    >
      Publish
    </Button>
  );
}

const buttonStyle = css`
  height: 48px;
  margin: 16px 0;
`;
