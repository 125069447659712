import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit';
import _ from 'lodash';

import { scrollToTop } from '../../utils/windowScroll';
import { runRoutingEvaluation } from '../evaluationResults/evaluationResultsSlice';
import { searchExistingResources } from '../resourceSearch/resourceSearchSlice';
import {
  fetchRoutingConfigByBuyerAndSku,
  fetchRoutingConfigById,
  fetchSkuCodeFromRoutingConfigId,
} from '../selectedConfiguration/actions';

interface AlertContent {
  error?: any;
  title: string;
  messages: string[];
  status?: 'danger' | 'warning' | 'info' | 'success';
}

interface TitleAndMessages {
  title: string;
  messages: string | string[];
  error?: any;
}

interface TitleAndOptionalMessages {
  title: string;
  messages?: string | string[];
}

const initialState: AlertContent = {
  messages: [],
  title: '',
};

const normalizeToArray = (messages: string | string[]) => {
  return _.isArray(messages) ? messages : [messages];
};

const AlertContentSlice = createSlice({
  name: 'alertContent',
  initialState,
  reducers: {
    setError(state, action: PayloadAction<TitleAndMessages>) {
      const { messages, title, error } = action.payload;
      state.messages = normalizeToArray(messages);
      state.title = title;
      state.status = 'danger';
      state.error = error;
    },
    setWarning(state, action: PayloadAction<TitleAndMessages>) {
      const { messages, title, error } = action.payload;
      state.messages = normalizeToArray(messages);
      state.title = title;
      state.status = 'warning';
      state.error = error;
    },
    setSuccess(state, action: PayloadAction<TitleAndOptionalMessages>) {
      const { messages, title } = action.payload;
      state.messages = messages ? normalizeToArray(messages) : [];
      state.title = title;
      state.status = 'success';
    },
    clearAlert(state) {
      state.messages = [];
      state.title = '';
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchExistingResources.rejected, (state, action) => {
        state.messages = [(action.payload as any) ?? 'An unexpected error occurred'];
        state.title = 'An error occurred while searching for item or order';
      })
      .addCase(runRoutingEvaluation.fulfilled, (state, action) => {
        if (action?.payload?._embedded?.decision) {
          state.messages = ['Routing evaluation completed successfully'];
          state.title = 'Evaluation Results';
          state.status = 'success';
          scrollToTop();
        }
      })
      .addCase(runRoutingEvaluation.rejected, (state, action) => {
        state.messages = action.error.message?.split('\n') || ['An unexpected error occurred'];
        state.title = 'Error evaluating routing configuration';
        state.status = 'danger';
        scrollToTop();
      })
      .addCase(fetchSkuCodeFromRoutingConfigId.rejected, (state, action: any) => {
        state.title = 'Error fetching skuCode for routing configuration';
        state.messages = action.payload?.message?.split('\n') || ['An unexpected error occurred'];
      })
      .addMatcher(
        isAnyOf(fetchRoutingConfigByBuyerAndSku.rejected, fetchRoutingConfigById.rejected),
        (state, action: any) => {
          state.messages = action.payload?.message?.split('\n') || ['An unexpected error occurred'];
          state.title = 'Error retrieving routing configuration';
          state.error = action.error;
        },
      );
  },
});

export const { setError, setWarning, clearAlert, setSuccess } = AlertContentSlice.actions;
export default AlertContentSlice.reducer;
