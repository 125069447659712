import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getSettings } from '@cimpress-technology/react-platform-settings/lib/SettingsClient';

interface GlobalSettings {
  timezone: string;
  triedToSetTimezone: boolean;
}

const initialState: GlobalSettings = {
  timezone: 'Etc/UTC',
  triedToSetTimezone: false,
};

export const getTimezone = createAsyncThunk('globalSettings/getTimezone', async () => {
  const settings = await getSettings();
  if (settings?.global?.timezone) {
    return settings.global.timezone as string;
  }
  return 'Etc/UTC';
});

const GlobalSettingsSlice = createSlice({
  name: 'globalSettings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTimezone.rejected, (state) => {
      state.triedToSetTimezone = true;
    });
    builder.addCase(getTimezone.fulfilled, (state, action) => {
      state.timezone = action.payload;
      state.triedToSetTimezone = true;
    });
  },
});

export default GlobalSettingsSlice.reducer;
