/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import _ from 'lodash';
import React from 'react';

import PlusIconButton from '../styledComponents/PlusIconButton';
import GeocodeRow from './GeocodeRow';
import { GeocodeCriteria } from './types';

export default function Geocode({
  value,
  conditionId,
  onUpdate,
}: {
  value: GeocodeCriteria[];
  conditionId: string;
  onUpdate: (value: GeocodeCriteria[]) => void;
}) {
  const addRow = () => onUpdate([...value, { country: '', stateOrProvinceCodes: [] }]);

  const updateRow = (criteria: GeocodeCriteria, index: number) => {
    const clonedCriteria = _.cloneDeep(value);
    clonedCriteria[index] = criteria;
    onUpdate([...clonedCriteria]);
  };

  const deleteRow = (index: number) => {
    const clonedCriteria = _.cloneDeep(value);
    clonedCriteria.splice(index, 1);
    onUpdate([...clonedCriteria]);
  };

  return (
    <>
      <div css={geocodeTableCss}>
        <span css={marginTop}>Leave state or province codes blank to allow all.</span>
        <span css={marginBottom}>
          For countries besides US & CA, type and press enter to create province codes.
        </span>
        {_.map(value, (v, index) => (
          <GeocodeRow
            conditionId={conditionId}
            value={v}
            index={index}
            onChange={(criteria: GeocodeCriteria) => updateRow(criteria, index)}
            onDelete={() => deleteRow(index)}
            key={`geocode-row-${index}`}
          />
        ))}
      </div>
      <div css={plusButton}>
        <PlusIconButton onClick={addRow} disabled={false} />
      </div>
    </>
  );
}

const geocodeTableCss = css`
  display: flex;
  flex-direction: column;
  grid-area: value;
  margin-left: 16px;
`;

const plusButton = css`
  grid-area: plus;
  margin-top: -8px;
  margin-bottom: 8px;
`;

const marginTop = css`
  margin-top: -16px;
`;

const marginBottom = css`
  margin-top: -6px;
  margin-bottom: 5px;
`;
