import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { Item, Product, getItem } from '../../services/items';

interface ProductConfigState {
  item?: Item;
  product?: Product;
  productConfigurationUrl?: string;
  isLoading: boolean;
}

interface ProductConfigSearchParams {
  itemId: string;
  timezone?: string;
}

const initialState: ProductConfigState = {
  isLoading: false,
};

export const fetchItemById = createAsyncThunk(
  'items/fetchById',
  async (params: ProductConfigSearchParams, { getState }) => {
    const state = getState() as any;
    return await getItem(params.itemId, params.timezone, state.environment);
  },
);

const ProductConfigurationSlice = createSlice({
  name: 'productConfiguration',
  initialState,
  reducers: {
    setProductConfigurationUrl(state, action: PayloadAction<string>) {
      state.productConfigurationUrl = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchItemById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchItemById.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(fetchItemById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.item = action.payload;
      state.productConfigurationUrl = action.payload.productConfigurationUrl;
    });
  },
});

export const { setProductConfigurationUrl } = ProductConfigurationSlice.actions;
export default ProductConfigurationSlice.reducer;
